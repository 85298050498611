.red {
    position: relative;
    left: 8px
}
.red::before {
    content: '';
    border: 3px solid red;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    left: -10px;
    top: 5px;
}
.green {
    position: relative;
    left: 8px
}
.green::before {
    content: '';
    border: 3px solid green;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    left: -10px;
    top: 5px;
}
.title {
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
}