.titleBox{
    overflow: hidden;
}
.title{
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
}
.pd{
    position: relative;
    padding-left: 12px;
}
.star{
    color:red;
    font-weight: bold;
    margin-right: 5px;
    position: absolute;
    left: 0;
    top: -2px;
    font-size: 20px;
}
.selectbox{
    overflow: hidden;
    margin-bottom:20px;
}
.lossfeature{
    width:20%;
    float: left;
    padding-right:8px;
    box-sizing: border-box;
}
.asof{
    width:20%;
    float: left; 
    padding-right:8px;
    box-sizing: border-box;
}
.Tdate{
    width:20%;
    float: left; 
    padding-right:8px;
    box-sizing: border-box;
}
.lossval{
    width:20%;
    float: left; 
    padding-right:8px;
    box-sizing: border-box;
}
.lossbtn{
    width:20%;
    float: left; 
    box-sizing: border-box;
    padding-top:20px;
}
.selects{
    width:100%;
    margin-top:5px;
}
.doubleSelects{
    width:48%;
    margin-top:5px;
}
.submitbtn{
    box-sizing: border-box;
    margin-right:5px;
}
.exportbtn{
    box-sizing: border-box;
    padding-left: 8px !important;
    padding-right: 12px !important;
}
