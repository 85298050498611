.dot {
    position: relative
}
.dot::before {
    content: '';
    border: 3px solid grey;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    left: -10px;
    top: 5px;
}
.red::before {
    border-color: red
}
.green::before {
    border-color: green
}
.yellow::before {
    border-color: #ff9800
}