.iconslist{
    float:right;
    width:200px;
    text-align: right;
    display: flex;
    justify-content: right;
  }
  .icbtn{
    margin:5px 10px;
  }
  .flexC{
    max-width: 500px;
  }
  .titleBox{}
  .titleBox,.SummaryBox{
    overflow: hidden;
  }
  .title{
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
  }
  .update{
    float:right;
    width:200px;
    color:#000;
    display:block;
    text-align: right;
  }
  .SummaryBox{
    overflow: hidden;
  }
  .Summary{
    display:inline-block;
    color:#000;
    margin:10px 0 10px; 
  }
/* ----- */
.grouptit{
    font-size: 14px;
    color:#000;
    margin-top:10px;
    position: relative;
    padding-left: 12px;
}
.group{
    width: 100%;
    overflow: hidden;
}
.pd{
  position: relative;
  padding-left: 12px;
}
.star{
    color:red;
    font-weight: bold;
    margin-right: 5px;
    position: absolute;
    left: 0;
    top: -2px;
    font-size: 20px;
}
.select{
    width:80%;
    float:left;
}
.subbtn{
    float:left;
    margin-left:20px;
}
.flexC{
    display: flex !important;
    flex-direction: column;
}
/* ------ */
.selectbox{
    overflow: hidden;
    margin-bottom:40px;
}
.lossfeature{
    width:40%;
    float: left;
    padding-right:8px;
    box-sizing: border-box;
}
.lossval{
    width:40%;
    float: left; 
    padding-right:8px;
    box-sizing: border-box;
}
.lossbtn{
    width:20%;
    float: left; 
    box-sizing: border-box;
    padding-top:21px;
}
.selects{
    width:100%;
    margin-top:5px;
}
.submitbtn{
    box-sizing: border-box;
    margin-right:5px;
}
.exportbtn{
    box-sizing: border-box;
    padding-left: 8px !important;
    padding-right: 12px !important;
}
.UnitX{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
}