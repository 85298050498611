.box{
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 16px 24px;
}
.previewBox{
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
}
.SectionTitle{
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #627d77;
  margin: 8px 0;
}
.Content{
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color:rgba(0, 0, 0, 0.85);
}