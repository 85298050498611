
.title {
    float: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */

    text-transform: capitalize;
    /* SR/sub */
    color: rgba(0, 0, 0, 0.5);
} 
.supNum {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-size: 20px;
    line-height: 21px;
    vertical-align: middle;
}
.increase {
    color: #ff3030;
}
.icon {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    position: relative;
    top: -2px;
}
.increaseIcon {
    background: url('../../assets/up.png') no-repeat center/contain;
}
.decreaseIcon {
    background: url('../../assets/down.png') no-repeat center/contain;
}
.decrease {
    color: #2A9C43;
}
.zero {
    color: #2A9C43
}
.num {
    text-align: right;
    margin-top: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-size: 44px;
    line-height: 51px;
    /* identical to box height */


    /* SR-VI */

    color: #627D77;


    /* Inside Auto Layout */
}