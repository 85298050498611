.titleBox{
    overflow: hidden;
}
.title{
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
}
.subTitle{
    font-size: 16px;
    color:#627d77;
    font-weight:600;
}
.SectionListTitle, .subTitleControl{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}
.CreatNewreport{
    text-align: right;
    padding: 20px 0;
}
.SectionBox{
    padding: 16px;    
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #627d77;
    margin-bottom: 8px;
}
.tr{
    text-align: right;
}
.submitbtn{
    box-sizing: border-box;
    margin-right:10px;
    border-radius:3px!important;
}
.exportbtn{
    box-sizing: border-box;
}
.btnbox{
    margin-top:30px;
}
.cardbox{
    margin-bottom:20px
}
/* preview */

.exportBox{
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: right;
    gap: 8px;
    align-items: center;
}
.previewTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box{
    background: #fff;
    border: 1px solid #627d77;
    border-radius: 2px;
    margin-bottom: 16px;
    padding: 8px 12px;
}

.info{
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
}