/* ----- */
.tabletit {
  margin-top: 20px;
  margin-bottom: 8px;
}
.editbtn {
  border-radius: 3px !important;
  box-sizing: border-box;
  padding-left: 10px !important;
  padding-right: 5px !important;
}
.iconedit {
  margin-right: 0px !important;
}
.tabletit span {
  margin-right: 10px;
  font-size: 14px;
}
.table {
  width: 100%;
  height: 200px;
  border-collapse: collapse;
  border: 1px solid #bbb;
  table-layout: fixed;
}

.SelectAge {
  width: 100%;
  height: 200px;
  margin-top: 20px;
  border: 1px solid #bbb;
}

/* ---TriangleCalculate-- */

.calculate{
    margin-top: 20px;
    display: flex; 
}
.control{
    display: flex;
    background: #fff;
}

.selectList{
    width: 100px;
}
.tableBox{
    flex: 1;
}
.radioGroup{
    width: 60px;
    text-align: center;
}
.radioItem{
    height: 39px;
    line-height: 30px !important;
    display: block !important;
}
.method, .selection{
    width: 110px;
}
.item{
    width: 100px;
    height: 39px;
    line-height: 39px;
    margin-top: 2px !important;
}
.itemS{
    height: 39px;
    line-height: 39px;
    margin-top: 2px;
}
.head{
    height: 39px;
    line-height: 39px;
    background-color: #fafafa;
    padding-left: 5px;
}
