.site-layout .site-layout-header {
  background: #fff;
  padding: 0 8px;
  box-sizing: border-box;
  min-width: 460px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* font-weight: 400; */
  font-size: 18px;
}

.site-layout .site-layout-header .site-layout-header-name {
  margin-left: 20px;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
}

.logo {
  margin: 0 auto;
  height: 60px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.logo.collapsed img {
  height: 15px;
  transition: all ease 0.5s;
}

.ant-menu-item.ant-menu-item-selected .ant-menu-title-content {
  color: #fff
}

.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #D8DEDD !important;
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active {
  background-color: #8B9E99 !important;
}

.ant-menu-dark .ant-menu-item.ant-menu-item-selected:hover,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-item .ant-menu-title-content {
  background-color: #627d77 !important;
}


.ant-pagination-item-active a {
  color: #333 !important;
}

.ant-pagination-item {
  font-size: 14px;
}

.ant-btn {
  border: 1px solid #627d77 !important;
  border-radius: 5px !important;
  padding: 4px 8px !important;
  color: #627d77 !important;
}
.ant-btn-primary{
  color: #fff !important;
}
.ant-btn-text {
  border: none !important;
}
.backlog {
  /* position: absolute; */
  bottom: 10px;
  padding: 10px;
  width: 100%;
  text-align: center;
  word-break: break-all;
  color: #000;
}
.ant-table-row:nth-child(odd){
  background: rgba(98,125,119, 0.2);
}