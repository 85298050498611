.sign-button {
    margin-right: 10px;
}
.sign-button a{
    border: 1px solid #627d77 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    border-radius: 8px;
    height: 32px;
    padding: 5.5px 8px;
    font-size: 16px;
}