.loginPage {
    height: 100%;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    /* background: url('../../../public/img/login_bg.jpg'); */
}
.title {
    position: absolute;
    height: 128px;
    line-height: 128px;
    width: 100%;
    top: 14vh;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-weight: bold;
    font-size: 80px;
    /* identical to box height */

    text-transform: capitalize;

    /* SR-VI */

    color: #627D77;


}
.titleNew {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    font-size: 10vh;
    line-height: 12vh;
    /* identical to box height */
    
    text-transform: capitalize;
    
    color: #d8ebff;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    
    width: 100vw;
    text-align: center;
    /* margin-top: 13.5vh; */
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
}
.loginBlock {
    position: absolute;
    left: calc(50% - 214px);
    top: 63.78%;

    width: 428px;
    height: 200px;
    margin: 0 auto;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    /* border: 1px solid rgba(255, 255, 255, 0.4); */
    box-sizing: border-box;
    backdrop-filter: blur(20px);
    /* Note: backdrop-filter has minimal browser support */
    width: 382px;
    top: initial;
    bottom: 15%;
    height: 135px;
    border-radius: 10px;
    background: #d8ebff;
    backdrop-filter: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo {
    height: 23vh;
    width: 23vh;
    margin-top: 7vh;
}
.iconNew {
    margin: 32px 0 0 32px;
    width: 155px;
}
.button {
    width: 352px;
    height: 32px;
    background-color:#627D77;
    color: #fff !important;
    border-radius: 8px;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    cursor: pointer;
    width: 316px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4292db;
}   
.loginContent {
    color: #fff;
}   
.loginContentDisabled {
    color: gainsboro;
}   
.icon {
    height: 36px;
    width: 36px;
    margin-right: 8px;
}
.agree{
    padding: 35px 72px 10px;
    color: #fff;
}
.agree a{
    color: #0099ff;
}
.agreeAlert{
    color: #fff;
    margin-left: 24px;
}
.checkAgree{
    margin-right: 5px !important;
}
.footer{
    display: flex;
    background: rgba(98, 125, 119, 0.5);
    height: 37px;
    line-height: 37px;
    color: #fff;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 10px;
    position: fixed;
    bottom: 0;
    width: 100vw;
}
.footer a{
    color: #fff;
}
.footer .footerItem{
    width: 360px;
}
.bei{
    width: 18px;
    margin-bottom: 4px;
}
.email{
    width: 18px;
    margin-bottom: 2px;
}
.application{
    color: #fff;
    margin: 10px auto;
}
.selectApplication{
    margin-right:20px; 
    width: 200px;
}