.export-title {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    color: #666;
    cursor: pointer;
}
.upload {
    display: inline-block;
    width: 100%;
}
.table-title {
    margin-top: 10px;
    font-weight: bold;
    color: #222;
}
.table-title span {
    color: #666
}
h3.ant-typography.apply-title {
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
}