.titleBox{
  overflow: hidden;
  margin-bottom: 20px;
}
.contentBox{
  padding: 10px;
  border: 1px solid #627D77;
  border-radius: 2px;
}
.p10{
  padding: 10px;
}
.title{
  color: #627D77;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 8px;
}
.subTitle{
  color: #627D77;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 8px;
  font-weight: bold;
}
.textTitle{
  color: #627D77;
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 12px;
  font-weight: bold;
}
.PremiumQuotation{
  color: #627D77;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 8px;
  margin-top: 32px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.PremiumQuotation span{
  font-size: 12px;
  font-weight: normal;
  color: #333;
}

