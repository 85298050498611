.claim-title {
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
}

.claim-tmxChart {
    /* width: 800px; */
    height: 300px;
}

.claim-alert-red {
    background-color: #FFF1F0 !important;
    border: 1px solid #FFA39E !important;
}
.claim-alert-red .ant-alert-message {
    color: #F5222D !important;
}

.claim-alert-green {
    background: #F6FFED !important;
    border: 1px solid #B7EB8F !important;
}
.claim-alert-green .ant-alert-message {
    color: #52C41A !important;
}
.claim-alert-gray {
    background: #FAFAFA !important;
    border: 1px solid #D9D9D9 !important;
}
.claim-alert-gray .ant-alert-message {
    color: rgba(0, 0, 0, 0.85) !important;
}