body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-form-inline .ant-form-item {
  /* margin-bottom: 10px; */
}

.ant-btn {
  box-shadow: none !important;
}

.ant-tabs-tab{
  font-size: 14px;
  color: #000;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  font-size: 14px;
  color: #000;
}

td.ant-table-column-sort{
  background: transparent;
}
