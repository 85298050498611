.titleBox{
    overflow: hidden;
}
.title{
    color: #627D77;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
}
.date{
    width:100%;
}

.submitbtn{
    box-sizing: border-box;
    margin-right:10px;
}
.exportbtn{
    box-sizing: border-box;
}
.btnbox{
    margin-top:30px;
    white-space: nowrap;
}
.cardbox{
    margin-bottom:20px
}