.welcontent {
    background: #fff;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    color: #627D77;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding: 12px;
}

.welcontenttitle {
    text-align: left;
    margin: 28px 0 32px 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
    font-style: normal;
    font-size: 20px;
    line-height: 23px;
    color: #627D77;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.welcontentbody{
    
}